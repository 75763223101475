
import React from "react";
import logosvg from '../../../images/logo_baner.png';
const Barra = () => {

    return(
        <div className="navegador_admin">
            <ul>
            <img src={logosvg} alt="PQM Sports" className="icono_logo_admin"/>
            <div className="container_admin_seconds">
            <button type="button" className="boton_panel" >
            Panel de Control
            </button>
            <button type="button" className=" boton_panel" >
            Estadisticas Facebook
            </button>
            <button type="button" className=" boton_panel" >
            Estadisticas Instagram
            </button>
            <a href="./" className="boton_panel" >
            Cerrar Sesion
            </a>
            </div>
            </ul>
        </div>
    );
}

export default Barra;