import {db} from '../../firebase';
import { collection, getDocs } from "firebase/firestore";
import {useEffect, useState} from 'react'

 export const Obtener_usuarios = () => {
    
    const [data, setData] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    ///Solo se ejecutara 1 vez
    useEffect(() =>{
        getData();
    },[])


    ///Metodo asincronico 
    const getData = async() => {
        try {
            setLoading(true);  
            const consulta = await getDocs(collection(db, "matbru_evento"));
            const Datos = consulta.docs.map(doc => ({id: doc.id, ...doc.data()}))
            setData(Datos);
        } catch (error) {
            setLoading(false);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }
    return {
        data,
        error,
        loading
    }
}