import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import reportWebVitals from './reportWebVitals';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'; // Archivo CSS de Bootstrap 4 
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'; // Archivo Javascript de Bootstrap 4 

import '../src/components/Css/App.css';
import '../src/components/Css/Reponsive.css';

import 'animate.css';


import { BrowserRouter,Routes,Route } from "react-router-dom";

/*Routas */
import Home from '../src/components/Home';
import Contacto from '../src/components/contacto';
import Admin from '../src/components/screens/admin/administrador';
import Representacion from '../src/components/screens/representacion';
import Eventos from '../src/components/screens/eventos';
import Escuelas from '../src/components/screens/home_escuelas';

/* Import de las escuales a las que estan afiliadas a PQM Sports */
import Sandifort from '../src/components/screens/escuelas/escuela';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route>
                <Route path="/" element={<Home />} />
                <Route path="contacto" element={<Contacto />} />
                <Route path="admin" element={<Admin />} />
                <Route path="Representacion" element={<Representacion/>} />
                <Route path="Eventos" element={<Eventos/>} />
                <Route path="escuelas" element={<Escuelas/>} />
                <Route path="pablo_sandifort" element={<Sandifort/>} />
            </Route>
        </Routes>
    </BrowserRouter>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


