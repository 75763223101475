import React from 'react';
import icono from '../../../images/logo_baner.png';
import {Outlet,Link} from 'react-router-dom';

const Navbar = () => {

    return (

<div className="ultimatum_nav">

<div className="nav justify-content-center ">

<nav className="navbar navbar-expand-lg ">
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
      <li className="nav-item active">
        <a className="nav-link" href="/">Nosotros</a>
      </li>
      <li className="nav-item">
        <Link className="nav-link xd_link" to="/Representacion">Representación</Link>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/">Marteking</a>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/eventos">Eventos</Link>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/"><img className="iconx" src={icono} alt=""/></a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/">Escuelas deportivas</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/">Portafolio</a>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/contacto">Contacto</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/admin">Login</Link>
      </li>
    </ul>

  </div>

</nav>
<Outlet />

</div>

</div>
    )
}

export default Navbar;