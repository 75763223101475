
import React from 'react';

import ReactPlayer from 'react-player'
import Barner_contacto from '../images/portada_contacto.png'
const Contacto = () => {



    return (
 
        <section id="contacto" className="container">
            <form className="contacto_section">
                <div className="contacto_izquierda">
                    <img  className="imagen_contacto_portada" src={Barner_contacto} alt=""/>

               </div>
                <div className="contacto_derecha">
                <h4 className='contactotitulo'>ESCRÍBENOS</h4>                    
                    <br></br>
                    <input type ="text" className="form-control text-center borde" name="nombres-apellidos" placeholder="Nombres y Apellidos"/>                    <br></br>
                    <div className="ooo">
                        <div>
                            <input type ="email" className="form-control text-center borde" id="exampleInputEmail1" name="correo electronico" placeholder="Correo electrónico"/>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div>
                            <input type ="number" className="form-control text-center borde" name="teléfono" placeholder="Teléfono"/>
                        </div>
                        
                    </div>
                    <br></br>
                    <select name="ciudad" className="form-control text-center borde">
                        <option value>
                            Elige el área a la que quieres contactar
                        </option>
                        <option value>
                            Representación
                        </option>
                        <option value>
                           Branding (Marketing)
                        </option>
                        <option value>
                            Audiovisual (Marketing)
                        </option>
                        <option value>
                            Digital (Marketing)
                        </option>
                        <option value>
                            Comunicación (Marketing)
                        </option>
                        <option value>
                            Patrocino (Marketing)
                        </option>
                        <option value>
                            Eventos (Marketing)
                        </option>
                        <option value>
                            Consultoría
                        </option>
                    </select>
                    <br></br>
                    <textarea type ="textarea" className="form-control text-center borde" name="describe tu proyecto" placeholder="Describe tu proyecto"/>
                    <br></br>
                    <input type="submit" className="btn btn-warning text-center"></input>
                </div>

                
  
            </form>

        </section>
    )
}

export default Contacto;
