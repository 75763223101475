import React,{useState} from "react";
import {FaRegUser,FaPhoneAlt,FaMapMarkerAlt,FaCalendarAlt,FaTransgenderAlt,FaSnapchatGhost,FaRegHeart} from 'react-icons/fa'
import { collection, addDoc  } from "firebase/firestore";
import {db} from '../../firebase';
import swal from 'sweetalert';


const Nuevo_Evento =()=>{
    const [titulo, setTitulo] =  useState('');
    const [tipo, setTipo] = useState('');
    const [deporte, setDeporte] = useState('');
    const [nombreTable, setNombreTable] = useState('');
    const [fecha, setFecha] = useState('');
   
  const envio = async () => {
    const fecha = new Date();

    const añoActual = fecha.getFullYear();
    const hoy = fecha.getDate();
    const mesActual = fecha.getMonth() + 1; 

    var fechita = hoy+"/"+mesActual+"/"+añoActual;
    /*
    console.log('Nombre es: '+nombre);
    console.log('Apellido es: '+apellidos);
    console.log('Telefono es: '+telefono);
    console.log('Edad es: '+edad);
    console.log('Ciudad es: '+ciudad);
    console.log('Sexo es: '+sexo);
    console.log('Correo es: '+correo);
    console.log('Pasatiempo es: '+pasa);
    */
 
    /*
    if(nombre !== '' & apellidos !== '' & telefono !== '' & edad.length === 1 || edad.length === 2  & telefono.length === 10 & pasa !==''){
      swal("Excelente!", "Te has registrado correctamente "+nombre+" "+apellidos+" !", "success");
      setActiveform(false);
      try {
        const docRef = await addDoc(collection(db, "formulario_inicial"), {
          nombres: nombre,
          apellidos: apellidos,
          telefono: telefono,
          edad: edad ,
          ciudad: ciudad,
          sexo:sexo,
          correo:correo,
          hooby:pasa,
          fecha_registro:fechita
         
        });
        console.log("Document written with ID: ", docRef.id);

      } catch (e) {
        swal ( "Oops" ,   e ,  "error" )

      }
      
    }else{

      swal ( "Oops" ,  "Ingresa todos los datos correctamente!" ,  "error" )
    }
  */
  

  }

    return (
          <div>

<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Creador de Eventos - PQM Sports</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        Porfavor, llene todos los campos. Una vez llenado todos los campos el evento aparecera en el aparto de "Eventos"
      </div>
      <div className="modal-footer">
                 
      <div className="FondoFormulario1">
        <div className="Fondo2">
            
        <div style={{justifyContent:'center',textAlign:'center'}}>
            <form>
                <div style={{display: 'flex',justifyContent:'center',textAlign:'center',alignItems:'center'}}>
                    
                <div className="form-control text-center" style={{display: 'flex',alignItems:'center'}}> 
                      <FaRegUser/><input type ="text" style={{borderColor:'transparent',backgroundColor:'transparent'}} className="form-control text-center" name="apellidos" onChange={event => setTitulo(event.target.value)} placeholder="Titulo del evento"/>
                    </div>    
                    
         
                    &nbsp;&nbsp;
                    <div className="form-control text-center" style={{display: 'flex',alignItems:'center'}}> 
                      <FaRegUser/><input type ="text" style={{borderColor:'transparent',backgroundColor:'transparent'}} className="form-control text-center" name="apellidos" onChange={event => setTipo(event.target.value)} placeholder="Tipo de evento"/>
                    </div>              
                </div>
                <br></br>
                <div style={{display: 'flex',justifyContent:'center',textAlign:'center',alignItems:'center'}}>
                    <input type ="date"  className="form-control text-center" name="teléfono" onChange={event => setFecha(event.target.value)} placeholder="Teléfono"/>
                  &nbsp;&nbsp;
                </div>

                <br></br>
                <div className="form-control text-center" style={{display: 'flex',alignItems:'center'}}> 
                  <FaRegHeart/>
                  <select name="hobby" style={{borderColor:'transparent',backgroundColor:'transparent'}} onChange={event => setDeporte(event.target.value)} className="form-control text-center">
                      <option value=''>Selecciona un deporte</option>
                      <option value="Fútbol">Fútbol</option>
                      <option value="Basket">Basket</option>
                      <option value="Atletismo">Atletismo</option>
                      <option value="Box">Box</option>
                      <option value="Ciclismo">Ciclismo</option>
                      <option value="Voley">Voley</option>
                      <option value="Lucha">Lucha</option>
                      <option value="Billar">Billar</option>
                      <option value="Maratón">Maratón</option>
                      <option value="Exhibiciones">Exhibiciones</option>
                      <option value="Cheerleader">Cheerleader</option>
                      <option value="Baile">Baile</option>
                      <option value="CrossFit">CrossFit</option>
                      <option value="Pesas">Pesas</option>
                      <option value="Otros">Otros</option>

                    </select>
                </div>
            </form>

        </div>
        </div>
        </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-warning" data-dismiss="modal"  >Cerrar</button>
                <button className="btn btn-success" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal"  onClick={envio} data-dismiss="modal">Crear evento</button>
       
            </div>
          </div>
        
      </div>
    </div>

    <button type="button" class="btn btn-success" data-toggle="modal" data-target="#staticBackdrop">
    Nuevo Evento
</button>
</div>
      
  );
}

export default Nuevo_Evento;