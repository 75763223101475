import React from 'react';

const Services = () => {

    return (
        <div className='services' id="services">
            <div className='contenedor_imagenes'>
                <div className="col-12 mainHeaderServices">
 
                </div>
                <div className='contenedor_imagenes'>
                    <div className='imagenes4'>
                        <div className='Imagen1'></div>
                        <div className='Imagen2'></div>
                        <div className='Imagen3'></div>
                        <div className='Imagen4'></div>
                    </div>
                    <div className='imagenes4'>
                        <div className='Imagen5'></div>
                        <div className='Imagen6'></div>
                        <div className='Imagen7'></div>
                        <div className='Imagen8'></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;