import React ,{useState}from 'react';

import Navbar from '../screens/funciones/Navbar';
import Logo2 from '../../images/Marcas/LG.png';
import Logo3 from '../../images/Marcas/zumba.jpg';
import Logo5 from '../../images/Marcas/adidas.png';
import Logo6 from '../../images/Marcas/JBRCOMPU.jpg';
import Logo8 from '../../images/Marcas/gilbert.jpg';
import Logo9 from '../../images/Marcas/Liga.png';
import Logo10 from '../../images/Marcas/Es.png';

import ReactPlayer from 'react-player'

// Render a YouTube video player

const Eventos = () => {
    
    const [state] = useState({
        descrip: 'Con música y baile @matbrufitnesscenter promueve una vida sana. Más de 450 personas participaron en la bailatón ‘Por una vida sana’ efectuada en los exteriores de @matbrufitnesscenter Las mujeres fueron el grupo más numeroso en esta actividad. Le siguieron jóvenes y adultos. Con el propósito de promover la salud de los duraneños, se efectuó el pasado domingo 12 de junio el bailatón evento en el cual nuestra marca participó como auspiciante. 🤸‍♀️💪',
        descripcion:'En el legendario Coliseo Luis Raffo de Durán, se desarrolló el I Torneo Relámpago de Básquet Masculino y Femenino. Diez equipos participantes provenientes de diferentes sectores de nuestra ciudad, dieron cuenta del resurgir de este deporte arraigado en la historia de nuestro cantón ferroviario.',
        asociado1: 'Carey - Soluciones informaticas y electronicas.',
        asociado2: 'Fitness Center Matbru.',
        asociado4: 'Fitness Zumba.',
        asociado3: 'Mr. Grill Sports & Games.',
        asociado5: 'JBRCOMPU',
        asociado6: 'LGILBERT',
        asociado7: 'CR Physical',

    });
    return(

    <div className='evento_container'>        
        <Navbar />
        <div className='eventos'>
            <div className='lado'> 
                <div className='evento_title'>Bailatón 2022 MATBRU</div>
                <div className='evento_subtitle'>{state.descrip}</div>
                <div className='title_asociados'>En auspiciado por</div>
                <div className='asociados'>{state.asociado2}</div>
  

                <div className='container row'>
                    <img  className="iconos_chiquitos" src={Logo10} alt=""/>
                </div>
            </div>
            <div className='evento_con'>
            <ReactPlayer loop muted playing url='https://youtu.be/J6d-mI7xHP8' />
            </div>
            

        </div>
        <li className='division'><div class="dropdown-divider" /></li>

        <div className='eventos'>
            <div className='lado'> 
                <div className='evento_title'>Copa Durán Cuna de Campeones</div>
                <div className='evento_subtitle'>{state.descripcion}</div>
                <div className='title_asociados'>En asociación con</div>
                <div className='asociados'>{state.asociado1}</div>
                <div className='asociados'>{state.asociado2}</div>
                <div className='asociados'>{state.asociado3}</div>
                <div className='asociados'>{state.asociado4}</div>
                <div className='asociados'>{state.asociado5}</div>
                <div className='asociados'>{state.asociado6}</div>
                <div className='asociados'>{state.asociado7}</div>

                <div className='container row'>
                    <img  className="iconos_chiquitos" src={Logo5} alt=""/>
                    <img  className="iconos_chiquitos" src={Logo10} alt=""/>
                    <img  className="iconos_chiquitos" src={Logo2} alt=""/>
                    <img  className="iconos_chiquitos" src={Logo3} alt=""/>

                    <img  className="iconos_chiquitos" src={Logo8} alt=""/>
                    <img  className='iconos_chiquitos' src={Logo6} alt=""/>
                    <img  className="iconos_chiquitos" src={Logo9} alt=""/>
                </div>
            </div>
            <div className='evento_con'>
            <ReactPlayer loop muted playing url='https://youtu.be/lS72tOuXrYM' />
            </div>
        </div>
    </div>

    );
}


export default Eventos;