
import React from 'react';
import icono from '../images/logo_baner.png';
import {Outlet,Link} from 'react-router-dom';
const Nav = () => {
    return (
      <header class="header-area header-sticky">
      <div class="container">
          <div class="row">
              <div class="col-12">
                  <nav class="main-nav">
                      <a href="index.html" class="logo">PQM<em> Sport</em></a>
                      <ul class="nav">
                          <li class="scroll-to-section"><a href="/">Inicio</a></li>
                          <li class="scroll-to-section"><a href="/Representacion">Representación</a></li>
                          <li class="scroll-to-section"><a href="#our-classes">Marteking</a></li>
                          <li class="scroll-to-section"><a href="/eventos">Eventos</a></li>
                          <li class="scroll-to-section"><a href="/escuelas">Escuelas deportivas</a></li> 
                          <li class="main-button"><a href="/admin">Login</a></li>
                      </ul>        
                      <a class='menu-trigger'>
                          <span>Menu</span>
                      </a>
                  </nav>
              </div>
          </div>
      </div>
  </header>
    )
}

export default Nav;