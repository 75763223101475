
import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import image1 from '../../../images/Escuelas/001/pablo1.jpeg'
import image2 from '../../../images/Escuelas/001/pablo2.jpeg'
import image3 from '../../../images/Escuelas/001/pablo3.jpeg'
//import image4 from '../../../images/Escuelas/001/pablo4.jpeg'
//import image5 from '../../../images/Escuelas/001/pablo5.jpeg'
import image6 from '../../../images/Escuelas/001/pablo6.jpeg'
//import image7 from '../../../images/Escuelas/001/pablo7.jpeg'
//import image8 from '../../../images/Escuelas/001/pablo8.jpeg'
 const Carrusel = () =>{
    return(
        <div>
               <Carousel autoPlay width='100%' infiniteLoop className="carrusel">
                <div>
                    <img  src={image1}/>
                    <p className="legend">Grand Prix Internacional - Brazil</p>
                </div>
                <div>
                    <img src={image2}/>
                    <p className="legend">Grand Prix Internacional - Brazil</p>
                </div>
                <div>
                    <img src={image3}/>
                    <p className="legend">Grand Prix Internacional - Brazil</p>
                </div>


                <div>
                    <img src={image6}/>
                    <p className="legend">Grand Prix Internacional - Brazil</p>
                </div>

            </Carousel>
            </div>
    )
}

export default Carrusel;

