import React,{useState} from 'react';

import logo from '../../../images/logo_baner.png';
import {auth} from '../../firebase';
import swal from 'sweetalert';
import { signInWithEmailAndPassword } from "firebase/auth";

import Dasboard from './dasboard';
///import Login from './login';

const Admin = () => {

    const[estado,setEstado]=useState(false);

    
    const[correo_user,setCorreo_user] = useState('');
    const[password_user, setPassword_user] = useState('');

    const verificacion = async() => {
        signInWithEmailAndPassword(auth, correo_user, password_user)
        .then((userCredential) => {
          // Signed in
          console.log(userCredential);
          setEstado(true);          
        })
        .catch((error) => {
          swal ( "Oops D:" , 'Ingrese los datos correctamente' ,  "error" )
  
        });
  
      } 

      if (estado) return <Dasboard/>

    return (
        <div className='login'>
          <div className='container_login'> 
          <br></br>
          <div className='logotipo_login'>
            <img src={logo} style={{width:150}}/>
  
          </div>
          <div className="mb-3">
            <label>Correo</label>
            <input
              onChange={event => setCorreo_user(event.target.value)}
              type="email"
              className="form-control"
              placeholder="Ingrese su correo electronico"
            />
          </div>
          <div className="mb-3">
            <label>Password</label>
            <input
              onChange={event => setPassword_user(event.target.value)}
              type="password"
              className="form-control"
              placeholder="Ingrese su password"
            />
          </div>
          <div className="mb-3">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"
              />
              <label className="custom-control-label" htmlFor="customCheck1">
                Recordarme
              </label>
            </div>
          </div>
          <div className="d-grid">
            <button type="submit" onClick={verificacion} className="btn btn-primary">
              Ingresar
              
            </button>
          </div>
          <p className="forgot-password text-right">
            Olvidaste tu <a href="#">contrasena?</a>
          </p>
          </div>
        </div>
      );

    

}

export default Admin;