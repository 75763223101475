

import React from 'react';
import Navbar from '../Navbar';
import escuela1 from '../../images/Escuelas/001/pablosandifor.webp'
import '../Css/Escuelas.css';
const Escuelas = () =>{
return(
    <div className='imagen_general_escuelas'>
        <Navbar/>
        <div className='container_escuela'>
        <div className='container'>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
            <a class="nav-link active" id="BOX-tab" data-toggle="tab" href="#BOX" role="tab" aria-controls="BOX" aria-selected="true">BOX</a>
            </li>
            <li class="nav-item" role="presentation">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">BÁSQUET</a>
            </li>
            <li class="nav-item" role="presentation">
            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">FÚTBOL</a>
            </li>
            <li class="nav-item" role="presentation">
            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">KICK BOXING</a>
            </li>
        </ul>
        
        
        
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="BOX" role="tabpanel" aria-labelledby="BOX-tab">
                <div className='contenedor_home_escuela'>
                    <div class="box">
                        <div className='etiqueta'>Pablo Sandiford Amador</div>
                        <a href="pablo_sandifort">
                            <img  alt="" src={escuela1} />
                            <div class="capa">
                                <h2>CLUB ESPECIALIZADO FORMATIVO PABLO SANDIFORD AMADOR</h2>
                                <h6>Dirección: Cantón Durán, junto al estadio municipal Pablo Sandiford.</h6>
                            </div>
                        </a>
                    </div>
                    
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                ...
            </div>
            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                ...
            </div>
            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                ...
            </div>
        </div>
        </div>
        </div>
    </div>
)


}

export default Escuelas;