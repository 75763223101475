import React from 'react';
import Logo2 from '../images/Marcas/LG.png';
import Logo3 from '../images/Marcas/Fifa.png';
import Logo5 from '../images/Marcas/adidas.png';
import Logo6 from '../images/Marcas/Polar.png';
import Logo8 from '../images/Marcas/Real.png';
import Logo9 from '../images/Marcas/Liga.png';
import Logo10 from '../images/Marcas/Es.png';



const Estadisticas = () => {
    const [state] = React.useState({
        title: 'ESO NO ES TODO...',
        mainContent: "TE MOSTRAMOS PARTE DE NUESTRAS ESTADÍSTICAS"

    });

    return (
        <div className='container'>
            <div className='estadisticas'>
            <div className='texto_estadisticas'>
                <h6 className='textoxd'>{state.title}</h6>
                <h5>{state.mainContent}</h5>
            </div>
            <div className='contenedor_estadisticas_numeros'>
                <div >
                    <h6>+1</h6>
                    <p>Años de experiencia</p>
                </div>
                <div >
                    <h6>+7</h6>
                    <p>Clientes de alto nivel</p>
                </div>
                <div >
                    <h6>+1</h6>
                    <p>Campañas y activaciones</p>
                </div>
                <div >
                    <h6>+1.450</h6>
                    <p>Seguidores gestionados en redes</p>
                </div>
            </div>
            
            <div className='texto_estadisticas'>
                <div className='skillBanner'>
                    <h6>Clientes que confian en <em>PQM Sport</em></h6>
                    <img  className="img-fluid margin_x" src={Logo2} alt=""/>
                    <img  className="img-fluid margin_x" src={Logo3} alt=""/>
                    <img  className="img-fluid margin_x" src={Logo5} alt=""/>
                    <img  className='img-fluid margin_x' src={Logo6} alt=""/>
                    <img  className="img-fluid margin_x" src={Logo8} alt=""/>
                    <img  className="img-fluid margin_x" src={Logo9} alt=""/>
                    <img  className="img-fluid margin_x" src={Logo10} alt=""/>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Estadisticas