
import React from 'react';
import Logo from '../../../images/logo_baner.png';
import Carrusel from '../funciones/carrusel';
const Sandifort = () =>{
return(
    <div className='escuela0' >
    <header class="header-area header-sticky">
      <div class="container">
          <div class="row">
              <div class="col-12">
                  <nav class="main-nav">
                      <a href="/" class="logo">PQM<em> Sport</em></a>
                      <ul class="nav">
                          <li class="scroll-to-section"><a href="#fecha">Inscribirse</a></li>
                      </ul>        
                      <a class='menu-trigger'>
                          <span>Menu</span>
                      </a>
                  </nav>
              </div>
          </div>
      </div>
  </header>

        <div className='container_esc_un'>
                    <div className='contenedor_escuela1x'>
                        <div>
                            <div className='titulo_esc_un_t'>Club Especializado Formativo  </div>
                            <div className='titulo_esc_un_t'>“Pablo Sandiford Amador”</div>
                            <Carrusel />
                        </div>
                        <div className='izquierda_xd'>
                            <div className='titulo_esc_un'>Formulario de Inscripcion</div>
                            <div className='input_esc_un'>
                                Nombre y apellidos del representante * 
                                <input  class="form-control" type="text" placeholder='Escribe el nombre y apellido del representante'/>
                            </div>
                            <div className='input_esc_un'>
                                Nombre y apellidos del participante * 
                                <input  class="form-control" type="text" placeholder='Escribe el nombre y apellido del participante'/>
                            </div>
                            <div className='izquierda_separado'>
                                <div className='input_esc_un'>
                                    Cedula del representante * 
                                    <input  class="form-control" type="text" placeholder='Escribe la cedula del representante'/>
                                </div>
                                <div className='input_esc_un'>
                                    Cedula del participante * 
                                    <input  class="form-control" type="text" placeholder='Escribe la cedula del participante'/>
                                </div>
                            </div>
                            <div className='input_esc_un'>
                                Correo Electronico * 
                                <input  class="form-control"  type="text" placeholder='Escribe tu correo electronico'/>
                            </div>
                            <div className='input_esc_un'>
                                Telefono * 
                                <input  class="form-control"  type="text" placeholder='Escribe el numero de telefono del representante'/>
                            </div>
                            <div className='input_esc_un'>
                                Edad * 
                                <input  class="form-control"  type="text" placeholder='Escribe la edad del participante'/>
                            </div>      
                            <div className='input_esc_unckec'>
                            <label className='checkbos_x'>
                                <input type="checkbox" className='checkbos_x'/>
                                 He leido y acepto la DECLARACION DE CONSENTIMIENTO DE PARTICIPACION E INSCRICCION *
                            </label>
                                </div>
                            <div>
                                <input type="button" className='btn btn-warning' value="Inscribirse"/>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='titlex_esc_un'>INFORMACIÓN</div>
                            <div className='contenedor_text1_escuela'>
                                <p>El Club Especializado Formativo "Pablo Sandiford" de la ciudad de Durán, afiliado a la Federación Ecuatoriana de Boxeo Reconocida por AIBA y AMBC desde el año 2012, actualmente es el monarca del Interclubes juvenil y Elite del boxeo, adjudicándose algunos campeonatos nacionales y cuenta también con participación en torneos internacionales como son el CONTINENTAL DE BOXEO, COPA CASTELLAN, COPA INDEPENDENCIA, EL GRAND PRIX, todas fuera del país como Republica Dominicana, España, Guatemala, Brasil etc, el Presidente actual del club es el Ing. Reinado Pacheco, con una amplia experiencia en la gestión dirigencial deportiva en la Ecuatoriana de boxeo.</p>
                            </div>
                        <div className='contenedor_text_p'>
                           
                          
                            <p>El Club "Pablo Sandiford" es uno de los clubes que nunca ha faltado a los Campeonatos Nacionales Interclubes que se vienen desarrollando desde el año 2012 y es que el éxito del club en sus participaciones, se debe también a la preparación permanente y profesional que les imparte el profesor Ramón Contreras entrenador certificado con “ UNA ESTRELLA INTERNACIONAL” de la International Boxing Association. (IBA), su técnica y preparación ha hecho que varios boxeadores de su club sean considerados para formar parte de la selección ecuatoriana de boxeo y puedan representar al país en juegos panamericanos.</p>
                            <p>La sede del club y lugar de entrenamiento esta ubicada en el cantón Duran, junto al estadio Municipal Pablo Sandiford.</p>
                            <p>El proceso de inscripción a estos servicios una vez que sean publicados, se podrá realizar vía on line llenando los datos de la ficha de registro adjunto y cancelando los valores correspondientes mediante deposito o transferencia bancaria a la cuenta de la empresa PQM-SPORT, ficha que deberá ser entregada en la sede del club adjuntando el comprobante de pago y copia de cedula.</p>
                            <p className='titlex_esc_un'></p>
                            <div className='titlex_esc_un'>INFORMACIÓN DE INTERES</div>
                            <p>El club de boxeo Pablo Sandiford lanzará próximamente su servicio de formación y entrenamiento “ Técnicas de Defensa Personal para Ejecutivos” en horario de 19:00pm a 21:00pm.</p>
                            <p>Otro servicio que el club Pablo Sandiford pondrá a disposición de los deportistas ya formados será el “Entrenamiento Avanzado y de alto rendimiento de KICK BOXING” ha realizarse en CUBA ciudad de LA HABANA con el aval de la Federación Ecuatoriana de Boxeo.</p>
                            <div className='titlex_esc_un'>PARA PAGO A TRAVES DE TRANSFERENCIA o DEPOSITO:</div>
                            <p>Una vez validado el registro de inscripción se le enviará la cuenta a su correo electrónico.</p>
                            <p>Adjuntar justificante de transferencia o depósito y enviar al correo: lbravoq@pqmsport.com</p>

                            <div className='titlex_esc_un'>Actividades</div>
                            <p>Asamblea, actividades deportivas, talleres ludicos de distintas modalidades, ludoteca, excursiones.</p>
                            <div className='titlex_esc_un'>Contacto</div>
                            <p>0963801563 - PQM Sport</p>
                        </div>
                    </div>
         
            </div>



    </div>
)
}

export default Sandifort;