import React from "react";
import loading from '../../../images/home.gif';

const Cargando = () => {


    return(
        <div className="container_cargando">
            <img src={loading} className='cargando'/>
            <h3 style={{color:'rgba(246,190,0,0.9',fontWeight:'bold',textAlign:'center'}}>Cargando...</h3>
        </div>
    );
}

export default Cargando;