
/*FIREBASE CONFIGURACIONES */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import {getAuth} from 'firebase/auth';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB567US8p2VQZ6etX8G5h981BahaustFSI",
  authDomain: "pqmsports.firebaseapp.com",
  databaseURL: "https://pqmsports-default-rtdb.firebaseio.com",
  projectId: "pqmsports",
  storageBucket: "pqmsports.appspot.com",
  messagingSenderId: "688915142225",
  appId: "1:688915142225:web:46e7d848f93a46d2b2ca70",
  measurementId: "G-J8HJ9CGREH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

const db = getFirestore(app);

const auth = getAuth(app);

export {auth,db};