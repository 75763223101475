import React from "react";
import { FaSafari, FaChartLine, FaWallet, FaUsers,FaTrophy,FaVolleyballBall } from 'react-icons/fa';
import img1 from "../../images/Jugadores/Principal/img1.jpg";
import img2 from "../../images/Jugadores/Principal/img3.jpg";
import img3 from "../../images/Jugadores/Principal/jugador11.jpg";
import Navbar from '../Navbar';
import ReactPlayer from 'react-player'

import '../Css/Representacion.css';

import logo from '../../images/home.gif';
const Representacion = () => {
    return(
    <div className="contenedor_principal_presentacion">
        <Navbar />
        <div className="container_representacion">
            <div className="portada_representacion">
                <img className="icono_representacion" src={logo} alt=""/>
                <div className="representacion_textox">
                    <h1>REPRESENTACIÓN</h1>
                    <h2>Gestión de imagen de los deportistas <h2 className="color_pqm">más destacados.</h2></h2>
                    <p>Potenciar la imagen de los deportistas preprofesionales y jóvenes talentos enfocados en la calidad y habilidad.</p>
                </div>
            </div>
            <div className="container">

            <div className="representacion_section2">
                <div className="container_repre_nosotros"> 
                    <ReactPlayer  className='video_por'  width={'100%'} loop height={300} muted playing url='https://youtu.be/lS72tOuXrYM' />
                    <h2>SOBRE NOSOTROS</h2>
                    <p>Las mejores experiencias deportivas para todos en cualquier escenario.</p>
                </div>
                <div className="container_repre_nosotros">
                    <ReactPlayer  className='video_por'  width={'100%'} loop height={300} muted playing url='https://www.youtube.com/watch?v=TtgPdIi0sWA' />
                    <h2>ÉLITE</h2>
                    <p>Trabajamos para profesionalizar el deporte amateur, en fútboll, básquet, box y otros.</p>
                </div>
            </div>
 

            <div class="container">
                <div class="filas_imagenes">
                    <div className="col">                    
                        <img src={img1} className='Imagen_representacion' alt=""/>
                    </div>
                    <div className="col">
                        <img src={img2} className='Imagen_representacion' alt=""/>
                    </div>
                    <div className="col">
                        <img src={img3} className='Imagen_representacion' alt=""/>
                    </div>
                </div>
            </div>
            <div className="contenedor_repre_servicios">
                <h2>SERVICIOS</h2>
                <div className="contenedor_repre_servicios_cuadros">
                    <div className='cuadro_repre'>              
                        <FaWallet className="iconos-servicios"/> 
                        <i class="card-title">REPRESENTACION</i>
                        <p class="card-text">Gestión de la carrera pre-profesional y negociación de contratos. Búsqueda de nuevas posibilidades en el mercado.</p>
                    </div>
                    <div className='cuadro_repre'>              
                        <FaChartLine className="iconos-servicios"/> 
                        <i class="card-title">MARKETING</i>
                        <p class="card-text">Llevamos la imagen de nuestros representados a un mayor nivel, trabajando su imagen personal y presencia digital, para un mejor posicionamiento en el mercado.</p>
                    </div>
                    <div className='cuadro_repre'>              
                        <FaUsers className="iconos-servicios"/> 
                        <i class="card-title">COMUNICACIÓN</i>
                        <p class="card-text">Asesoramos en la comunicación y modulamos el estilo del mensaje a nivel personal y profesional tanto en medios públicos como privados y en los canales digitales.</p>
                         </div>  
                    <div className='cuadro_repre'>              
                        <FaSafari className="iconos-servicios"/> 
                        <i class="card-title">POST CARRERA</i>
                        <p class="card-text">Buscamos formar personas y ayudarles en su desarrollo futuro, mas allá de su carrera deportiva.</p>
                        
                    </div> 
                    
                </div>
            </div>
            
            <div className="container">
                <div className="title_talento">
                    <FaTrophy/>
                    TALENTOS
                </div> 
                <div className="talento_cont">
                    Búsqueda, seguimiento y gestión del talento de jóvenes deportistas.
                </div>
                <div className="talento_cont">
                    Potenciamos el valor de nuestros talentos, acompañándolos en todas las etapas del camino hacia el éxito de su carrera deportiva.
                </div>
            </div>

            <br></br>
            <br></br>
            <br></br>
            <div className="motivacion">
                <div className="title_talento">
                    <FaVolleyballBall />
                    MOTIVACIÓN
                </div> 
                <div className="talento_cont">
                    Vive el deporte amateur como un profesional….
                </div>
            </div>    
        </div>
        </div>
    </div>

    );
}

export default Representacion;