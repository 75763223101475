import React from 'react'



const About = () => {
    const [header] = React.useState({
        subHeader: '',    
        title: '',
        mainContent: ''
    });


    return (

        <section className='container' id="about">
            <div className="informacion_home">
                <h2>NUESTRO ENFOQUE EN <em>TRES ÁREAS</em></h2>
                <p>Somos una empresa que nace en Durán con el objetivo de brindar asesoría en las áreas de comunicación y marketing deportivo a través de la innovación, creatividad, profesionalismo y gestión de alianzas estratégicas con instituciones del deporte y sector empresarial.</p>
            </div>
        </section>
    )
}

export default About 