import React, { useState } from 'react';
import { CSVLink } from "react-csv";

import Sorteo from '../funciones/sorteo';
/* Icono del excel*/
import {FaChartBar} from 'react-icons/fa'
/* Obtencion de DATOS*/
import {Obtener_usuarios} from '../funciones/obtener_usuarios';
import {Obtener_eventos} from '../funciones/obtener_eventos';

/*Paginacion  */
import Pagination  from './paginacion';

/*Loading */
import Cargando from '../funciones/cargando';

/*Nuevo evento*/
import Nuevo_Evento from '../funciones/nuevo_evento';
import Barra from '../funciones/barra_lateral';
import LineChart from '../funciones/graficas';
import '../../Css/Admin.css';

const Dasboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(20);
  

  ///Usa para descomponer y obtener los datos del Retunr
    const {data,error,loading} = Obtener_usuarios();

    const {eventos} = Obtener_eventos();

    if (loading) return <Cargando/>
    if (error) return <p>Se ha encontrado un error: {error}</p>
    
    const registros = data.length;
  


    /*---------------Paginacion-----------------------*/

 // Get current posts
 const indexOfLastPost = currentPage * postsPerPage;
 const indexOfFirstPost = indexOfLastPost - postsPerPage;
 const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

 // Change page
 const paginate = pageNumber => setCurrentPage(pageNumber);



    return (
<div>
  <Barra/>
    <div className='container'>
      <div className="row">
        <div className="col-sm-6">
          <div className="card text-center">
            <div className="card-header">
              <ul className="nav nav-pills card-header-pills">
                <li className="nav-item">
                  <button type="button" class="btn btn-secundary" data-toggle="modal" data-target="#staticBackdrop">
                      Eventos
                  </button>
                  </li>
                <li className="nav-item">
                  <Nuevo_Evento/>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="contenedor_eventos">
                {
                eventos.map(item => (
                  <button type="button"  key={item.id} className="list-group-item list-group-item-action">
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1">{item.titulo}</h5>
                      <small>{item.fecha}</small>
                    </div>
                    <p className="mb-1">{item.tipo}</p>
                    <small>{item.Deporte}</small>
                  </button>         
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Estadisticas del evento</h5>
              <div className='estadistica_grafica'>
              <LineChart analizar={data}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='derecha'>
      <div className='row_panel'>

      <div className='container_herramienta'>          

        <div className='boton_herramienta'>          
              Registrados: &nbsp;<div className='registros'> {registros}</div>
        </div>    
        <div className='boton_herramienta'>          
          <CSVLink data={data}>Descargar Excel <FaChartBar/></CSVLink>
          </div> 
          <div className='boton_herramienta'>          
            <Sorteo datos={data}/>
          </div> 
        </div> 
          <div className='herramienta_paginacion'>          
          <Pagination 
          postsPerPage={postsPerPage}
          totalPosts={data.length}
          paginate={paginate}
          />
        </div>     
        <div> 
        </div>    

 
    
 </div>

      <table className="table table-responsive table-bordered" >
                    <thead className="thead">
                      <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Apellidos</th>
                        <th scope="col">Registrado</th>
                        <th scope="col">Edad</th>
                        <th scope="col">Telefono</th>
                        <th scope="col">Correo</th>
                        <th scope="col">Sexo</th>
                        <th scope="col">Ciudad</th>
                        <th scope="col">Hobby</th>

                      </tr>
                    </thead>
                    <tbody>
                      {
                        currentPosts.map(item => (
                          
                          <tr key={item.id}>
                            <td>{item.nombres}</td>
                            <td>{item.apellidos}</td>    
                            <td>{item.fecha_registro}</td>          
                            <td>{item.edad}</td>          
                            <td>{item.telefono}</td>          
                            <td>{item.correo}</td>          
                            <td>{item.sexo}</td>          
                            <td>{item.ciudad}</td>          
                            <td>{item.hooby}</td>          

                          </tr>
                        ))
                        }
                    </tbody>
                  </table>
                  </div>
           
            

    
      </div>


      </div>


    );

}

export default Dasboard;