import React from 'react';



// Import owner
import Inicio from './Inicio';
import About from './About';
import Estadisticas from './Estadisticas';
import Services from './Services';
import Portafolio from './Portafolio';

import Contacto from './contacto';
import Footer from './Footer';


function Home() {


  return (
        <div className="home">
    
            <Inicio/>
            <About />
            <Portafolio />
            <Services />
            <Estadisticas />
            <Contacto />
            <Footer />
        </div>
  );
}

export default Home;






