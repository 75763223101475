import React from 'react'
import icono from '../images/home.gif';
import {Link} from 'react-router-dom';
import { FaPhoneAlt, FaEnvelope, FaFacebookMessenger, FaClock, FaMapMarkerAlt } from 'react-icons/fa'
import { FaFacebook, FaInstagram,FaWhatsapp,FaYoutube } from 'react-icons/fa';

const Footer = () => {

    return (
        <div className='footer'>
            <div className='container'>
                <div className='filas'>
                    <div>
                        <h5>PQM SPORT te escucha y cubre tus expectativas, dinos lo que necesitas</h5>
                    </div>
                    <div className='boton_contacto'>
                        <a  href='#contacto' className='portafolio__box-url'>
                            Contactame
                        </a >
                    </div>
                </div>
                <div className='commonBorder'></div>
                <div className='footer__ul'>
                    <div className='parteizquierdafooter'> 
                        <a href='#projects'><img className="iconx" src={icono} alt=""/></a>
                        <h6>Somos una compañía independiente, especializada en el mundo del deporte.</h6>
                    </div>
                    <div className='partederechafooter'> 
                        <footer className='footer_xd'>
                            <div className='iconos_contacto'>
                                <FaPhoneAlt/>
                                <i>0963801563</i>  
                            </div>
                            <div className='iconos_contacto'>    
                                <FaClock/>
                                <i>Siempre Abierto</i>
                            </div>
                            <div className='iconos_contacto'>
                                <FaEnvelope/>
                                <i>lbravoq@pqmsport.com</i>
                            </div>
                            <div className='iconos_contacto'>
                                <FaMapMarkerAlt/>
                                <i><a href="https://www.google.com/maps/dir/-2.1780288,-79.8409335/-2.1675981,-79.8472273/@-2.1773843,-79.8566151,15z/data=!3m1!4b1!4m4!4m3!1m1!4e1!1m0" target="_blank"> Eloy Alfaro 215 y 16 de Octubre 090701 Durán, Ecuador</a></i>                        
                            </div>
                        </footer>                        
                    </div>
                </div>
                <div className='commonBorder'></div>
                <div  className='footerlogo'>
                    <div className='aboutCircles'>
                        <ul className='footer__ulx'>
                            <a href="https://www.instagram.com/pqmsport/" >
                                <li><FaInstagram /></li>
                            </a>
                            <a href="https://www.facebook.com/pqmsport">
                                <li><FaFacebook /></li>
                            </a>
                            <a href="https://www.facebook.com/pqmsport/videos/3139183976398562" >
                                <li><FaYoutube /></li>
                            </a>
                            <a href="https://api.whatsapp.com/send?phone=963801563&text=PQM%20Sports%20Hola!">
                                <li><FaWhatsapp /></li>
                            </a>
                        </ul>
                    </div>
                        <div className='centrado_footer'>
                            <p>COPYRIGHT © PQM Sports 2022. Todos los derechos reservados. </p>
                            <p>Desarrollado por Luigy Miranda.</p>
                        </div>

                    </div>
                </div>
            </div>
   

    )
}

export default Footer