

import icono from '../../../images/logo2.png';

import React, {  useState } from "react";
import ganador from '../../../images/ganador.gif';
const Sorteo = ({datos}) =>{

    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [telefono, setTelefono] = useState('');
    const [correo, setCorreo] = useState('');

    const [estado, setEstado] = useState(false);
    const [loading, setLoading] = useState(true);

    const Generar_ganador = () => {
        setLoading(false);
        setEstado(true);
        
        const aleatorio = datos[Math.floor(Math.random() * datos.length)];

        setNombre(aleatorio.nombres);
        setApellido(aleatorio.apellidos);
        setTelefono(aleatorio.telefono);
        setCorreo(aleatorio.correo);
        return(
            <div>
                <img src={ganador} alt="" className='imagen_ganador'/>
                <br></br>
                <h5>Felicidades! </h5>
                <h5>{nombre +' '+ apellido}</h5>
                <h5>Telefono: {telefono}</h5>
                <h7>Correo: {correo}</h7>
            </div>
        );


    }

    return(
        <div>
            <button type="button" className="btn btn-warning" data-toggle="modal" data-target="#exampleModal">
            Iniciar sorteo
            </button>

            <div className="modal fade" id="exampleModal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Sorteo</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                       {estado ? <div style={{textAlign:'center'}}>
                             <Generar_ganador/>
                        </div>
                      :''} 
                       {loading ? <div style={{textAlign:'center'}}>
                            <img className='iconx'  alt=""  src={icono}/>
                            <h6>Presiona Sorteo para sortear a un ganador!</h6>
                        </div>
                      :''} 

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    <button type="button" className="btn btn-danger" onClick={Generar_ganador}>Sortear</button>
                    <button type="button" className="btn btn-success" data-dismiss="modal">Guardar Ganador</button>


                </div>
                </div>
            </div>
            </div>
        </div>
    )
}


export default Sorteo;