import { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function LineChart({analizar}) {
    
const roots = analizar.map(function(item) {
    

    return item.edad;

});

let labels = [];
 
const tempArray = [...roots].sort();
let contador = 0;
for (let i = 0; i <= tempArray.length; i++) {
    labels.push(contador);
    contador=contador+1;

}


const options = {
  fill: true,
  responsive: true,
  scales: {
    y: {
      min: 0,
    },
  },
  plugins: {
    legend: {
      display: true,
    },
  },
};

  const data = useMemo(function () {
    return {
      datasets: [
        {
          label: "Edad ",
          data: roots,
          tension: 0.3,
          borderColor: "rgb(75, 192, 192)",
          pointRadius: 1,
          pointBackgroundColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.3)",
        },
        {
          label: "Fecha Registro ",
          data: roots,
          tension: 0.3,
          borderColor: "rgb(104, 102, 192)",
          pointRadius: 1,
          pointBackgroundColor: "rgb(104, 102, 192)",
          backgroundColor: "rgba(104, 102, 192, 0.3)",
        },
      ],
      labels,
    };
  }, []);

  return <Line data={data} options={options} />;
}