import React,{useState} from 'react';
import ReactPlayer from 'react-player'



const Portafolio = () => {

 


    const [state] = useState(
        {
            heading: 'IMAGEN Y REPRESENTACION DE DEPORTISTAS',
            textService: "Representación de deportistas de élite y jóvenes talentos, enfocados a la calidad, ofreciendo un servicio que cubre todas las necesidades del deportista con un servicio exclusivo, cercano y confiable.",
            heading1: 'AGENCIA DE MARKETING DEPORTIVO',
            textService1: 'La variedad y alternativas de innovación que ofrecen los eventos deportivos los convierten en una herramienta efectiva para crear experiencias únicas y mover gran cantidad de público y audiencia en plataformas digitales. Promover o impulsar la venta de productos de cualquier categoría deportiva o servicio relacionado con la actividad física o hábito nutricional, usando los espacios de las plataformas deportivas.',
            heading2: 'GESTIÓN DE ESCUELAS DEPORTIVAS',
            textService2: 'Gestionamos todo tipo de academias deportivas, escuelas municipales, apoyo a clubes deportivos, con un proceso en el que ayudamos a diseñar e implementar las campañas más efectivas y los canales para llegar a tus potenciales usuarios.',
        },
    );


    return (
    <div className='container'>
        <div className='portafolio'>
            <div className='portafolio__box'> 
                <div className='video_por'>
                    <ReactPlayer  className='video_por'  width={'100%'} loop height={200} muted playing url='https://youtu.be/lS72tOuXrYM' />
                </div>
                <div className='contenedor_abajo_porta'>
                    <h4 className="title_porta">{state.heading}  </h4>   
                    <details className='detalles_porta'>
                    <summary className='summary_em'>Más información</summary>
                        <p className="text_porta">{state.textService}</p>
                    </details>
                </div>
            </div>
            <div className='portafolio__box'> 
                <div className='video_por'>
                    <ReactPlayer   className='video_por' width={'100%'}  loop height={200} muted playing url='https://www.youtube.com/watch?v=MA4wGU3FPwU' />
                </div>
                <div className='contenedor_abajo_porta'>
                    <h4 className="title_porta">{state.heading1}</h4>
                    <details className='detalles_porta'>
                    <summary className='summary_em'>Más información</summary>
                        <p className="text_porta">{state.textService1}</p>
                    </details>
                </div>
            </div>
            <div className='portafolio__box'> 
                <div className='video_por'>
                    <ReactPlayer  className='video_por' width={'100%'} height={200}  loop muted playing url='https://youtu.be/TtgPdIi0sWA' />
                </div>
                <div className='contenedor_abajo_porta'>
                    <h4 className="title_porta">{state.heading2}</h4>
                    <details className='detalles_porta'>
                    <summary className='summary_em'>Más información</summary>
                    <p className="text_porta">{state.textService2}</p>
                </details>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Portafolio;

