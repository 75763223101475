
    import React ,{useState} from 'react';
    ///import Matbru from '../components/formularios/formulario_matbru';
    import imagen from '../images/home.gif';
    import { FaFacebook, FaInstagram, FaYoutube,FaWhatsapp } from 'react-icons/fa';
    import video from '../video/inicio.mp4';
    import what from '../images/what.png'
    import Nav from '../components/Navbar';
    const Inicio = () => {
    
        return (
            <div>
                <Nav/>
                <div class="main-banner" id="top">
                <video src={video} autoPlay loop muted id="bg-video" />       
    
                <div class="video-overlay header-text">
                    <div class="caption">
                        <h6>¡Nosotros lo hacemos, tú lo vives!</h6>
                        <h2>PQM <em>Sport</em></h2>
                        <p>¡Las mejores experiencias deportivas para todos en cualquier escenario!</p>

                    </div>

                </div>

            </div>
                <div className="container-boton">
                    <a href="https://api.whatsapp.com/send?phone=963801563&text=PQM%20Sports%20Hola!" target="_blank">
                        <img className="boton" src={what} alt="" />
                    </a>
                </div>
        </div>     
    
        )
    }
    
    export default Inicio;